import bgm from 'audio/bgm.mp3';
import coverCafeBar from 'images/brand/cover-cafe-bar-restaurant.jpg';
import coverMasion from 'images/brand/cover-maison.jpg';
import coverShihlin from 'images/brand/cover-shihlin.jpg';
import coverShop from 'images/brand/cover-shop.jpg';
import coverMuseum from 'images/brand/cover-taipei-fine-arts-museum.jpg';
import cover101 from 'images/brand/cover-101.jpg';

import type { Intro as MenuIntro } from 'components/molecules/Menu';

import type { ImageAlign, TourData } from 'utils/types/customization';

interface ModelConfig {
    tools: {
        info: boolean;
        infoDefault?: boolean;
        position: boolean;
        positionDefault?: boolean;
        highlight: boolean;
        tour: boolean;
        view: boolean;
        floors: boolean;
        share: boolean;
        screenshot: boolean;
        getLocation: boolean;
    };
    menu: boolean;
    music?: string;
    intro?: MenuIntro[];
    displayTourList?: string[];
    defaultTour: string;
    defaultTitle: string;
    tourData: {
        [key: string]: TourData;
    };
    fixedLink: {
        text: string;
        url: string;
    };
}

const modelConfig: ModelConfig = {
    tools: {
        info: true,
        infoDefault: true,
        position: true,
        positionDefault: false,
        highlight: true,
        tour: true,
        view: true,
        floors: true,
        share: true,
        screenshot: false,
        getLocation: false,
    },
    music: bgm,
    menu: true,
    intro: [
        {
            type: 'EN',
            content:
                'ACME is an independent lifestyle brand from Taipei founded in 2018. Starting as a brunch restaurant & cafe aiming to offer the best brunch experience in town, ACME obtained recognition for its aesthetics and community. In 2022, ACME opens its flagship restaurant in Taipei Performing Arts Center which is one of the most pioneering architecture of the world by Rem koolhaas from OMA. Now it expands to include in-house clothing collections, restaurants, cafes, gallery space and lifestyle shop.',
        },
        {
            type: 'ZH',
            content:
                '⽣活風格暨餐飲品牌「ACME」，2018年以早午餐打響名號⽽後推出新咖啡店型「CAFE ACME」，咖啡門市現皆選址於都市難得的清幽地點，提供精緻的歐式早午餐和在地烘焙的好咖啡。從餐飲延伸到生活方式，現在更將理念觸及藝術⼈文，2021年進駐臺北市立美術館接續MAISON ACME圓山別邸經營權，透過咖啡和藝術傳遞對於⽣活的理念及風格。2022年成為全球最受矚目建築 - 臺北表演藝術中心唯一指定餐廳，在200坪空中花園的擁簇下，持續用早午餐實現ACME品牌哲學。',
        },
    ],
    displayTourList: ['cafe', 'maison', 'shop', 'taipei101', 'shihlin', 'museum'],
    defaultTour: 'cafe',
    defaultTitle: 'EXPLORE ACME 一探究竟',
    tourData: {
        cafe: {
            text: 'ACME  |  Cafe Bar & Restaurant',
            title: 'ACME  |  Cafe Bar & Restaurant',
            path: 'cafe-bar-restaurant',
            cover: coverCafeBar,

            modelId: 'bJ1wfKz2BKM',
            sr: '-2.93,-1.55',
            ss: '213',

            sweepId: '3e8316e65239416e99fdf6713abaa2b0',
            position: {
                x: 3.2117791175842285,
                y: 1.628737211227417,
                z: 3.704226493835449,
            },
            rotation: {
                x: -0.25022713918383743,
                y: -91.16497500437387,
            },
        },
        maison: {
            text: 'ACME  |  MAISON',
            title: 'ACME  |  MAISON',
            path: 'maison',
            cover: coverMasion,

            modelId: 'W7rhnpFDPEa',
            sr: '.02,.29',
            ss: '107',

            sweepId: 'e023807003d94ccaa831191bbac35d2a',
            position: {
                x: 35.02726745605469,
                y: 0.6689746379852295,
                z: 16.369049072265625,
            },
            rotation: {
                x: 1.0980606095229402,
                y: 16.618916476699084,
            },
        },
        shop: {
            text: 'ACME  |  SHOP',
            title: 'ACME  |  SHOP',
            path: 'shop',
            cover: coverShop,

            modelId: 'W7rhnpFDPEa',
            sr: ',1.24',
            ss: '19',

            sweepId: 'c13eda8b8d1246e5b116fe01a789bc0a',
            position: {
                x: 7.970557689666748,
                y: 4.186673641204834,
                z: -2.5952515602111816,
            },
            rotation: {
                x: -0.09089742889834614,
                y: 71.00548768711477,
            },
        },
        taipei101: {
            text: 'CAFE ACME  |  Taipei 101',
            title: 'CAFE ACME  |  Taipei 101',
            path: 'taipei101',
            cover: cover101,

            modelId: 'rrsNrU7hwU9',
            sr: '1.39,1.56',
            ss: '2',

            sweepId: '3477718835fe458faabe941339a6fc69',
            position: {
                x: -13.128022193908691,
                y: 1.6181994676589966,
                z: -3.1899912357330322,
            },
            rotation: { x: -3.7619265147464906, y: -144.76524186058415 },
        },
        shihlin: {
            text: 'CAFE ACME  |  Shihlin',
            title: 'CAFE ACME  |  Shihlin',
            path: 'shihlin',
            cover: coverShihlin,

            modelId: 'MG5bfucBJsp',
            sr: '3.14,.58',
            ss: '48',

            sweepId: '56b155ff527b42adabd13776bfa40ecf',
            position: {
                x: 8.261665344238281,
                y: 1.4403347969055176,
                z: -6.4587931632995605,
            },
            rotation: { x: 0.076329155169332, y: 146.76841457187317 },
        },
        museum: {
            text: 'CAFE ACME  |  Taipei Fine Arts Museum',
            title: 'CAFE ACME  |  Taipei Fine Arts Museum',
            path: 'taipei-fine-arts-museum',
            cover: coverMuseum,

            modelId: 'W7rhnpFDPEa',
            sr: '-3.11,-.89',
            ss: '204',

            sweepId: '486f55aaa8b8403ab8e1487d3ed57bbd',
            position: {
                x: 47.670169830322266,
                y: 4.373603343963623,
                z: -6.927558898925781,
            },
            rotation: { x: -1.1392004371430977, y: -128.9927696790683 },
        },
    },
    fixedLink: {
        text: 'acmetaipei.com',
        url: 'https://acmetaipei.com/',
    },
};

export const imageAlignConfig: {
    default: ImageAlign;
    exceptions: {
        [key: string]: ImageAlign;
    };
} = {
    default: 'center',
    exceptions: {
        GV8UK95cKyD: 'top',
        '5AM5Mlz3GyQ': 'top',
    },
};

export default modelConfig;
